<template>
	<div class="form-x-wrap">
		<input
			ref="formX"
			:placeholder="$attrs.placeholder || value()"
			:value="value()"
			@change="change"
			@focus="setApp('status.cursorOnInput', 1)"
			@blur="setApp('status.cursorOnInput', 0)"
			v-bind="$attrs"
			/>
	</div>
</template>
<script>
import baseMixin from '../mixins/base'
import formX from '../mixins/formX'
export default {
	name: 'InputX',
	inheritAttrs: false,
	mixins: [baseMixin, formX]
}
</script>
<style lang="scss" scoped>
.form-x-wrap {
	display: inline-flex;
	input {
		flex-grow: 1;
	}
}
input::placeholder {
	color: $c-dark-blue;
	font-size: 14px;
}
</style>