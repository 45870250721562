export const throttle = (fn, delay = 0) => {
    let canRun = true
    return function () {
        if (!canRun) return
        canRun = false
		fn.apply(this, arguments)
        setTimeout(() => {
            canRun = true
        }, delay)
    }
}
