export default {
	props: {
		defaultValue: {
		},
		prop: {
			default: ''
		}
	},
	computed: {
		style() {
			return this.prop.replace('newStyle.', '').replace('style.', '')
		}
	},
	created() {
		if (this.defaultValue !== undefined) {
			this.setValue(this.defaultValue)
		}
	},
	mounted() {
		this.setApp('refs.formX', this.getApp('refs.formX').concat(this))
	},
	destroyed() {
		const formX = this.getApp('refs.formX')
		formX.splice(formX.indexOf(this), 1)
		this.setApp('refs.formX', formX)
	},
	methods: {
		value() {
			const value = this.prop.includes('newStyle.') ? this.getApp('newStyleCompose.' + this.style) : this.prop.includes('style.') ? this.getStyle(this.style, true) : this.getProp(this.prop, this.$app)
			return this.$attrs.type === 'number' ? (parseInt(value) || 0) : value
		},
		setValue(value) {
			this.change({
				target: { value }
			})
		},
		change($event = {
			target: this.$refs.formX
		}) {
			this.prop.includes('newStyle.') ?
				this.setApp('newStyle.' + this.style, $event.target.value + (this.numberProps.includes(this.style) ? 'px' : '')) :
			this.prop.includes('style.') ?
				this.inputStyle(this.style, $event) :
				this.setProp(this.prop, $event.target.value, this.$app)
			this.forceUpdate()
		}
	}
}