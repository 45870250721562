<template>
<div id="app" class="app-root select-no">
	<header class="reset-css">
		<aside>
			<data>
				{{parseInt((($refs.editor || {}).editorScale || 0) * 100)}}
			</data>
			<dfn>
				%
			</dfn>
		</aside>
		<ToolBar/>
		<aside>
			<!-- <img src="@/assets/icon/icon-handle-guideline.svg" class="icon padding" title="显示辅助线"
				:class="{active: shiftKey}"
				@click="shiftKey = !shiftKey"/> -->
			<img src="@/assets/icon/icon-handle-border-dotted.svg" class="icon padding" title="显示边线"
				:class="{active: status.showFrameBorder}"
				@click="$toggle(status, 'showFrameBorder')"/>
			<img src="@/assets/icon/icon-handle-get-code.svg" class="icon" title="导出代码"
				@click="exportCode">
		</aside>
	</header>
	<main>
		<section class="reset-css">
			<WidgetsMenu :widgets="widgets" :app="_self"/>
		</section>
		<Editor ref="editor"
			@mouseenter="status.cursorOnEditor = 1;"
			@mouseleave="status.cursorOnEditor = 0;"/>
		<figure ref="figure" class="reset-css"
			@mouseenter="status.cursorOnFigure = 1;"
			@mouseleave="status.cursorOnFigure = 0;formXChange();">
			<fieldset>
				<legend @click="formToggle">
					新建属性
				</legend>
				<form onsubmit="return false">
					<div>
						div
					</div>
					<div>
						<label>定位</label>
						<SelectX v-show="status.cursorOnFigure || status.cursorOnWidget" prop="newStyle.position">
							<option value="static">静态</option>
							<option value="relative">相对</option>
							<option value="absolute">绝对</option>
						</SelectX>
						<SelectX v-show="!status.cursorOnFigure && !status.cursorOnWidget" :disabled="true" :defaultValue="1">
							<option :value="1">绝对</option>
						</SelectX>
					</div>
					<div class="v-center h-between">
						<div>
							<label>背景</label>
							<InputX prop="newStyle.backgroundColor" :placeholder="status.cursorOnFigure || status.cursorOnWidget ? 'transparent' : 'white'" style="width: 160px;"/>
						</div>
						<RecoverButton v-if="newStyle.backgroundColor" @click="delete newStyle.backgroundColor;forceUpdate();"/>
					</div>
				</form>
			</fieldset>
			<fieldset v-if="curWidget">
				<legend @click="formToggle">
					当前属性
				</legend>
				<form onsubmit="return false">
					<div>
						{{curWidget.tagName}}
					</div>
					<div>
						<label>定位</label>
						<SelectX v-show="!curWidget.isRoot()" prop="style.position">
							<option value="static">静态</option>
							<option value="relative">相对</option>
							<option value="absolute">绝对</option>
						</SelectX>
						<SelectX v-show="curWidget.isRoot()" :disabled="true" :defaultValue="1">
							<option :value="1">绝对</option>
						</SelectX>
					</div>
					<div>
						<label>X</label>
						<InputX prop="style.left" type="number"/>
						<label>Y</label>
						<InputX prop="style.top" type="number"/>
					</div>
					<div>
						<label>宽</label>
						<InputX prop="style.width" type="number"/>
						<label>高</label>
						<InputX prop="style.height" type="number"/>
					</div>
					<div>
						<label>外边距</label>
					</div>
					<div>
						<label>上</label>
						<InputX prop="style.marginTop" type="number"/>
						<label>右</label>
						<InputX prop="style.marginRight" type="number"/>
					</div>
					<div>
						<label>下</label>
						<InputX prop="style.marginBottom" type="number"/>
						<label>左</label>
						<InputX prop="style.marginLeft" type="number"/>
					</div>
					<div>
						<label>背景</label>
						<InputX prop="style.backgroundColor" style="width: 160px;"/>
					</div>
					<!-- <div>
						style
						<textarea :value="getProp('style')"></textarea>
					</div> -->
					<hr>
					<div class="v-start column">
						<b>弹性布局</b>
						<small class="c-gray"> 子元素定位需为静态 </small>
						<p class="h-5"/>
					</div>
					<div>
						<div>间隙</div>
						<label>X</label>
						<InputX prop="style.columnGap" type="number"/>
						<label>Y</label>
						<InputX prop="style.rowGap" type="number"/>
					</div>
					<div class="h-between v-center">
						布局<RecoverButton @click="TEMP.flexClassH='h-start';removeFlexClass();removeFlexStyle();"/>
					</div>
					<div class="flex row">
						<div>
							<div class="flex-direction-pad">
								<SvgArrow v-for="item in ['column-reverse', 'row', 'column', 'row-reverse']"
									@click="setStyle('flex-direction', item)"
									:class="item + ' btn ' + (getStyle('flex-direction') === item ? 'active' : '')"
									:key="item"/>
							</div>
							<div :class="'flex-wrap-pad ' + 'flex-wrap-' + getStyle('flex-direction')">
								<div v-for="item in ['nowrap', 'wrap', 'wrap-reverse']"
									@click="setStyle('flex-wrap', item)"
									:class="'flex-wrap-' + item + ' btn ' + (getStyle('flex-wrap') === item ? 'active' : '')"
									:style="(['column', 'row-reverse'].includes(getStyle('flex-direction')) ? 'wrap' === item : 'wrap-reverse' === item) ? {flexDirection: 'row-reverse'} : {}"
									:key="item">
									<SvgArrow :H="31" v-show="'nowrap' !== item"/>
									<SvgArrow :class="{'arrow-short': 'nowrap' !== item}"/>
								</div>
							</div>
						</div>
						<div class="flex-h-v-pad">
							<div class="flex row">
								<div class="flex column">
									<div class="flex-h-pad flex column">
										<div v-for="item in [TEMP.flexClassH.replace('h-', ''), 'around', 'between'].map(item => ['h-' + item, 'v-' + getStyle('align-items').replace('flex-', '')])"
											@click="setFlexClass(item[0])"
											:class="item.join(' ') + ' btn ' + (getStyle('justify-content').includes(item[0].split('-')[1]) ? 'active' : '')"
											:key="item.join(' ')">
											<mark v-for="n in 3" :key="n"/>
										</div>
									</div>
								</div>
								<div class="flex column">
									<div class="nine-grid-pad flex wrap">
										<div v-for="item in flexNineGridClasses"
											@click="nineGridHandle(item)"
											:class="(getStyle('justify-content') === 'space-around' ? ['h-center', item[1]] : item).join(' ') + ' btn'"
											:key="item.join(' ')">
											<template v-if="getStyle('justify-content').includes(item[0].split('-')[1]) && getStyle('align-items').includes(item[1].split('-')[1])">
												<mark v-for="n in 3" class="bg-black-i" :key="n"/>
											</template>
											<mark v-else-if="isFlexSpace() && getStyle('align-items').includes(item[1].split('-')[1])" class="bg-black-i"/>
											<mark v-else class="circle bg-silver"/>
										</div>
									</div>
									<div class="flex-vh-pad flex row">
										<div v-for="item in ['center', 'around', 'between'].map(item => ['vh-' + item, 'h-center', 'wrap'])"
											@click="setFlexClass(item[0])"
											:class="item.join(' ') + ' btn ' + (getStyle('align-content').includes(item[0].split('-')[1]) ? 'active' : '')"
											:key="item.join(' ')">
											<mark v-for="n in 3" :key="n"/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</fieldset>
			<fieldset>
				<legend @click="formToggle">
					编辑器属性
				</legend>
				<form onsubmit="return false" style="display: none;">
					<div class="v-center h-between">
						<div>
							<label>背景</label>
							<InputX prop="editorStyle.backgroundColor" style="width: 160px;"/>
						</div>
						<RecoverButton @click="editorStyle.backgroundColor = '#dddddd'"/>
					</div>
				</form>
			</fieldset>
		</figure>
	</main>
	<footer class="w-100p h-30 bg-bright h-between" style="flex-shrink: 0;">
		<div class="ph-10">UIX</div>
		<div v-if="curWidget">
			{{ curWidget.isRoot() ? '根元素' : curWidget.indexes.map(item => item + 1).join(' - ') }}
		</div>
		<div class="ph-10">®️自家用™ <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备19048334号-2</a></div>
	</footer>
</div>
</template>

<script>
import InputX from './components/InputX'
import SelectX from './components/SelectX'
import ToolBar from './components/ToolBar'
import Editor from './components/Editor'
import SvgArrow from './components/SvgArrow'
import SvgRefresh from './components/SvgRefresh'

import logoToolPoint from '@/assets/icon/icon-tool-point.svg'
import logoToolCorner from '@/assets/icon/icon-tool-corner.svg'
import logoToolFrame from '@/assets/icon/icon-tool-frame.svg'

const WidgetsMenu = ({ props: { widgets, app } }) => {
	// const { widgets, app } = props
	const classDt = item => ({
		'c-rose': app.selectFrames.includes(item),
		pointer: true
	})
	return widgets.map(item => <dl key={item.id}>
		<dt class={classDt(item)} onClick={e => {
			app.curWidget = item
		}}># {item.name}</dt>
		<dd style="margin: 0;">
			<WidgetsMenu widgets={item.children} app={app}/>
		</dd>
	</dl>)
}

const RecoverButton = ({
	props: {
		className = 'icon mini',
		fill = '#6060dd',
		title = '恢复默认'
	}, data }) => {
	return <SvgRefresh class={className} fill={fill} title={title} {...data}/>
}

export default {
	name: 'App',
	components: {
		InputX,
		SelectX,
		WidgetsMenu,
		SvgArrow,
		RecoverButton,
		ToolBar,
		Editor
	},
	data() {
		return {
			TEMP: {
				flexClassH: 'h-start'
			},
			refs: {
				formX: []
			},
			newClass: [
				'uix-base'
			],
			newStyle: {
			},
			editorStyle: {
				backgroundColor: '#dddddd'
			},
			wrapperStyle: {
				left: 0,
				top: 0,
				width: 0,
				height: 0
			},
			status: {
				cursorOnEditor: 0,
				cursorOnFigure: 0,
				cursorOnInput: 0,
				cursorOnWidget: 0,
				showFrameBorder: 1
			},
			tools: [
				{
					key: 'v',
					title: '选择',
					img: logoToolPoint
				},
				{
					key: 'g',
					title: '编组',
					img: logoToolCorner
				},
				{
					style: {
						padding: '2px',
						width: '30px'
					},
					key: 'f',
					title: '格子',
					img: logoToolFrame
				}
			],
			shiftKey: false,
			curWidget: null,
			selectFrames: [],
			dragWidgetDom: null,
			widgets: [],
			curTool: null
		}
	},
	computed: {
		newStyleCompose() {
			return Object.assign({}, {
				x: this.shiftKey,
				justifyContent: 'flex-start', // css 默认值 normal 跟 flex-start 一致
				alignItems: 'flex-start', // css 默认值 normal 跟 stretch 一致 且只能在 flex-wrap: nowrap 下起作用 情况比较复杂
				alignContent: 'flex-start', // css 默认值 normal 跟 stretch 一致
				// backgroundColor: this.status.cursorOnWidget ? 'transparent' : '#FFFFFF',
				boxSizing: 'border-box',
				position: 'static',	// NOTE 更改这个起始默认定位还需同时修改 uix-base 里的定位
				display: 'flex'
			}, this.newStyle)
		},
		flexNineGridClasses() {
			const dir = ['start', 'center', 'end']
			const res = []
			dir.forEach(V => {
				dir.forEach(H => {
					res.push([`h-${H}`, `v-${V}`])
				})
			})
			return res
		}
	},
	watch: {
		selectFrames(items) {
			this.$nextTick(() => {
				// TODO 改成全局只有一个 group widget 不用添加 删除
				this.widgets.forEach(widget => {
					if (widget.type === 'group') {
						this.widgets.splice(this.widgets.indexOf(widget), 1)
					}
				})
				const offset = {
					offsetX: 0,
					offsetY: 0
				}
				const style = {
					width: '0px',
					height: '0px'
				}
				let leftWidget, topWidget, maxLeft, maxRight, maxTop, maxBottom
				items.forEach(widget => {
					const { left, right, top, bottom } = widget.dom.getBoundingClientRect()
					if (maxLeft === undefined || left < maxLeft) {
						maxLeft = left
						leftWidget = widget
					}
					if (maxRight === undefined || right > maxRight) {
						maxRight = right
					}
					if (maxTop === undefined || top < maxTop) {
						maxTop = top
						topWidget = widget
					}
					if (maxBottom === undefined || bottom > maxBottom) {
						maxBottom = bottom
					}
				})
				const due = (widget, type) => {
					if (widget) {
						offset['offset' + type] += widget.getProp('offset' + (type === 'X' ? 'Left' : 'Top')) || 0
						due(widget.parent, type)
					}
				}
				if (items.length > 0) {
					due(leftWidget, 'X')
					due(topWidget, 'Y')
					style.width = (maxRight - maxLeft) / ((this.$refs.editor || {}).editorScale || 0) + 'px'
					style.height = (maxBottom - maxTop) / ((this.$refs.editor || {}).editorScale || 0) + 'px'
				}
				// if (items.length > 1) {
				// 	this.$refs.editor.addWidget(offset, {
				// 		type: 'group',
				// 		style,
				// 		tagName: 'div'
				// 	})
				// }
				this.wrapperStyle = {
					...style,
					left: offset.offsetX + 'px',
					top: offset.offsetY + 'px'
				}
			})
		},
		curWidget(item) {
			if (item == null) {
				this.selectFrames = []
			}
			if (item?.type === 'frame') {
				if (this.shiftKey) {
					if (this.selectFrames.includes(item)) {
						this.selectFrames.splice(this.selectFrames.indexOf(item), 1)
					} else {
						this.selectFrames.push(item)
					}
				} else {
					this.selectFrames = [item]
				}
			}
		}
	},
	methods: {
		forceUpdate() {
			this.shiftKey = !this.shiftKey
			this.shiftKey = !this.shiftKey
			this.$forceUpdate()
		},
		exportCode() {
			const editor = this.$refs.editor.$refs.editor
			Array.from(editor.children || []).forEach(root => {
				if (root !== this.$refs.editor.$refs.editor_etc) {
					console.log(root)
				}
			})
		},
		formXChange() {
			this.refs.formX.forEach(item => {
				item.$refs.formX.blur()
				// setTimeout(() => {
				// 	item.setValue(item.$refs.formX.value)
				// })
			})
		},
		formToggle(e) {
			e.target.nextSibling.toggle()
		},
		isFlexSpace() {
			return this.getStyle('justify-content').includes('space')
		},
		nineGridHandle(item) {
			this.setFlexClass(this.isFlexSpace() ? item[1] : item)
			this.TEMP.flexClassH = item[0]
		},
		setWrapperStyle(props = ['left', 'top']) {
			const styles = this.getStyles(['width', 'height'])
			const offset = {
				left: 0,
				top: 0
			}
			const due = (widget, prop) => {
				if (widget) {
					offset[prop] += widget.getProp('offset' + prop[0].toUpperCase() + prop.slice(1)) || 0
					due(widget.parent, prop)
				}
			}
			props.forEach(prop => {
				due(this.curWidget, prop)
			})
			this.wrapperStyle = {
				...this.wrapperStyle,
				...styles,
				...offset
			}
		}
	},
	created() {
		window.$app = this
	}
}
</script>

<style>
html,body {
	height: 100%;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
</style>

<style scoped lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #5c6c8c;
	font-size: 14px;
}
#app {
	height: 100%;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
#app {
	display: flex;
	flex-direction: column;
}
#app * {
	box-sizing: border-box;
}
header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	// position: absolute;
	width: 100%;
	top: 0;
	z-index: 1;
	background: #222f2f;
	background: #eeeeee;
	color: #ddee00;
	color: #6060dd;
	aside {
		padding: 0 10px;
		min-width: 80px;
		align-self: center;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.active {
		background: #fff;
		outline: 2px outset $c-rose;
	}
}
main {
	position: relative;
	flex-grow: 1;
	display: flex;
	overflow: hidden;
	flex-direction: row;
}
fieldset {
	padding: 0;
    margin: 0;
    border: 0;
	outline: 1px outset $c-white;
}
figure {
	width: 260px;
	margin: 0;
	position: absolute;
	top: 0;
	right: 0;
	background: $c-bright;
	text-align: left;
	legend {
		background: $c-rose;
		color: $c-white;
		word-break: break-all;
		width: 100%;;
		padding: 5px;
		text-align: center;
		font-size: 16px;
		cursor: pointer;
	}
	form {
		padding: 10px;
		label {
			text-align: center;
			min-width: 15px;
			display: inline-block;
		}
		/deep/ input, /deep/ select {
			box-sizing: border-box;
			margin: 5px 0 5px 5px;
			background: transparent;
			border: 0;
			width: 80px;
		}
		button {
			cursor: pointer;
			border: 0;
			background: transparent;
		}
		.btn {
			width: 30px;
			height: 30px;
			padding: 5px;
			cursor: pointer;
		}
		svg {
			fill: $c-silver;
			filter: contrast(0.7);
		}
		.active svg, svg.active {
				fill: $c-black;
				filter: unset;
		}
		.active {
			background: $c-snow;
			outline: 2px outset $c-rose !important;
			z-index: 1;
		}
	}
}
section {
	text-align: left;
	min-width: 120px;
	height: 100%;
	overflow: auto;
	z-index: 1;
}
dl {
	padding: 0 10px;
	dt {
		word-break: keep-all;
		white-space: nowrap;
	}
}
.flex-direction-pad {
	width: 80px;
    height: 80px;
    position: relative;
	margin: 10px;
	margin-bottom: 15px;
	.btn {
		position: absolute;
	}
	.column-reverse {
		top: 0;
		left: 50%;
		margin-left: -15px;
	}
	.row {
		transform: rotate(90deg);
		right: 0;
		top: 50%;
		margin-top: -15px;
	}
	.column {
		transform: rotate(180deg);
		bottom: 0;
		left: 50%;
		margin-left: -15px;
	}
	.row-reverse {
		transform: rotate(270deg);
		left: 0;
		top: 50%;
		margin-top: -15px;
	}
}
.flex-wrap-pad {
	display: flex;
	flex-wrap: nowrap;
	margin: 5px;
	outline: .5px outset $c-white;
	.btn {
		display: flex;
		justify-content: center;
		outline: .5px dotted $c-silver;
	}
	&.flex-wrap-column .btn {
		transform: rotate(180deg);
	}
	&.flex-wrap-row .btn {
		transform: rotate(90deg);
	}
	&.flex-wrap-row-reverse .btn {
		transform: rotate(270deg);
	}
	&.flex-wrap-column-reverse .btn {
		transform: rotate(0deg);
	}
	.arrow-short {
		height: 12px;
		align-self: flex-end;
	}
}
.flex-h-v-pad {
	.nine-grid-pad {
		width: 90px;
		margin: 5px;
		outline: 1px outset $c-white;
		outline-offset: -5px;
	}
	.btn {
		display: flex;
		gap: 1px;
		mark {
			width: 10px;
			height: 20px;
			display: flex;
			background-color: $c-silver;
			&:nth-of-type(1) {
				height: 15px;
			}
			&:nth-last-of-type(1) {
				height: 10px;
			}
			&.round {
				height: 10px;
			}
		}
	}
	.active {
		mark {
			background-color: $c-black;
		}
	}
	.flex-vh-pad {
		mark {
			width: 20px;
			height: 10px;
			display: flex;
			&:nth-of-type(1) {
				width: 15px;
			}
			&:nth-last-of-type(1) {
				width: 10px;
			}
			&.round {
				width: 10px;
			}
		}
	}
	.flex-h-pad,.flex-vh-pad {
		outline: .5px outset $c-white;
		margin: 5px;
		.btn {
			padding: 0;
			outline: .5px dotted $c-silver;
		}
	}
	.flex-h-pad {
		.btn {
			mark {
				width: 5px;
			}
		}
	}
	.flex-vh-pad {
		.btn {
			mark {
				height: 5px;
			}
		}
	}
}
.icon {
	cursor: pointer;
	height: 30px;
	margin: 0 5px;
	vertical-align: sub;
	&.padding {
		padding: 5px;
	}
	&.mini {
		width: 15px;
		height: 15px;
	}
}
</style>
