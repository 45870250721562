<template>
	<component
		ref="widgetDom"
		:is="item.tagName"
		:draggable="curTool === 'v'"
		:class="[
			...item.classList || [],
			$style._widget_class__widget_wrap,
			{
			[$style._widget_class__show_frame_border]: $app.status.showFrameBorder,
			}
		]"
		:style="item.style"
	>
		<WidgetVue v-for="(item, index) in item.children" :key="item.id"
			ref="widgets"
			:item="item"
			:indexes="[...indexes, index]"
			:getWidget="getWidget"
			:editorScale="editorScale"
			@click.stop.native="curTool === 'v' && setApp('curWidget', getWidget([...indexes, index]))"
			@dragstart.self.native="$emit('dragstartWidget', $event, getWidget([...indexes, index]))"
			@dragstartWidget="$emit('dragstartWidget', ...arguments)"
			/>
	</component>
</template>

<script>
import baseMixin from '../mixins/base'
export default {
	name: 'WidgetVue',
	mixins: [baseMixin],
	props: {
		getWidget: Function,
		editorScale: Number,
		indexes: Array,
		item: Object
	}
}
</script>

<style module lang="scss">
._widget_class__widget_wrap {
	display: block;
	outline: none;
}
._widget_class__show_frame_border {
	outline: 1px dotted $c-dark-blue;
}
</style>
