<template>
	<div class="editor-wrap" @wheel.prevent="wheelEditor">
		<div class="editor" ref="editor"
			:style="getApp('editorStyle')"
			@drop="drop"
			@dragover.prevent="dragoverThrottle"
			@dragleave.self="dragleave"
			@mousedown.left="drawStart"
			@mouseup.left="drawOver"
			@click.stop="curTool === 'v' && setApp('curWidget', null)"
			>
			<WidgetVue v-for="(item, index) in widgets" :key="item.id"
				ref="widgets"
				:item="item"
				:indexes="[index]"
				:getWidget="getWidget"
				:editorScale="editorScale"
				@click.stop.native="curTool === 'v' && setApp('curWidget', getWidget([index]))"
				@dragstart.self.native="dragstartWidget($event, getWidget([index]))"
				@dragstartWidget="dragstartWidget"
				@mouseenter.native="setApp('status.cursorOnWidget', 1)"
				@mouseleave.native="setApp('status.cursorOnWidget', 0)"
				/>
			<div ref="editor_etc">
				<div ref="mask" class="mask-over" v-show="maskShow"></div>
				<div :style="{transform: `scale(${1/editorScale}) translate(-${50*editorScale}%, -${50*editorScale}%)`}" class="center" style="color: #ff0077;">+</div>
				<Wrapper
					:editorScale="editorScale"
					@dragstartResizer="dragstartResizer"
					@mouseenter.native="setApp('status.cursorOnWidget', 1)"
					@mouseleave.native="setApp('status.cursorOnWidget', 0)"
					/>
			</div>
		</div>
		<div class="center" style="color: blue;">+</div>
	</div>
</template>

<script>
import baseMixin from '../mixins/base'
import Wrapper from './Wrapper'
import WidgetVue from './Widget'
import { throttle } from '../assets/js/util'
export default {
	name: 'Editor',
	components: {
		Wrapper,
		WidgetVue
	},
	mixins: [baseMixin],
	data() {
		return {
			editorScale: 1,
			dragHandler: null,
			// offsetStyle: null,
			// offsetStart: 0,
			offsetXY: {
				x: 0,
				y: 0
			},
			maskShow: false // 防止控件移动的时候 鼠标事件的offset指向控件 而不是editor
		}
	},
	computed: {
		// TODO 待删除 性能考虑 做保留
		curParentStyle() { // 当前选中元素的父级元素样式
			if (!this.curWidget) return
			const parentDom = this.curWidget.dom.parentElement
			const { left, top, right, bottom } = getComputedStyle(parentDom)
			return {
				...getComputedStyle(parentDom),
				left,
				top,
				right,
				bottom
			}
		},
		editorWrapWH() {
			return {
				width: this.editorWrap.clientWidth,
				height: this.editorWrap.clientHeight
			}
		},
		editorWrap() {
			return this.editor.parentElement
		},
		editorStyle() {
			return getComputedStyle(this.$refs.editor)
		},
		editor() {
			return this.$refs.editor
		}
	},
	methods: {
		// TODO 修剪
		getWidgetDom(indexes = [this.widgets.length - 1]) {
			let widgets = this.$refs.widgets || []
			let widget
			indexes.forEach((i, index) => {
				if (index === indexes.length - 1) {
					widget = widgets[i].$refs.widgetDom
				} else {
					widgets = widgets[i].$refs.widgets || []
				}
			})
			return widget
		},
		// TODO 修剪
		getWidget(indexes = [this.widgets.length - 1]) {
			let widgets = this.widgets || []
			let widget
			indexes.forEach((i, index) => {
				if (index === indexes.length - 1) {
					widget = widgets[i]
				} else {
					widgets = widgets[i].children || []
				}
			})
			return widget
		},
		// 【start】editor view controller
		editorMatrix() {
			// 不能改成 computed
			// eslint-disable-next-line no-undef
			return new WebKitCSSMatrix(this.editorStyle.transform)
		},
		zoomToAll() {
			console.log('command trigger: zoom to All')
		},
		zoomToSelection() {
			console.log('command trigger: zoom to Selection')
		},
		centerSelection() {
			console.log('command trigger: center Selection')
		},
		centerEditor() {
			const { width: wrapWidth, height: wrapHeight } = this.editorWrapWH
			const { width, height } = this.editorStyle
			this.editorScale = this.editorMatrix().m11
			this.editor.style.transform = `translate(${(parseInt(wrapWidth) - parseInt(width)) / 2}px, ${(parseInt(wrapHeight) - parseInt(height)) / 2}px) scale(${this.editorScale})`
			console.log('command trigger: center Editor')
		},
		wheelEditor(e) {
			// console.log(e.offsetX, e.layerX, e.pageX, e.clientX, e.screenX, e.x)
			// console.log('deltaX Y Z', e.deltaX, e.deltaY, e.deltaZ)
			// console.log('wheelDelta X Y', e.wheelDelta, e.wheelDeltaX, e.wheelDeltaY, e.wheelDeltaZ)
			// TODO transform-origin
			// this.editor.style.transformOrigin = `${e.layerX / parseInt(width) * 100}% ${e.layerY / parseInt(height) * 100}%`
			if (e.ctrlKey) {
				this.transformEditor({ scale: (1 - e.deltaY / 100) * this.editorScale })
			} else {
				this.transformEditor(e)
			}
		},
		transformEditor({ scale = this.editorScale, deltaX = 0, deltaY = 0 } = {}) {
			const { width: wrapWidth, height: wrapHeight } = this.editorWrapWH
			const { width, height } = this.editorStyle
			const { m41, m42, m11 } = this.editorMatrix()
			this.editorScale = m11
			// TODO 以下 / 2 是以 editor 中心作为 transform-origin 来算的？
			const maxLeft = 0 - parseInt(width) * (1 - scale) / 2
			const maxTop = 0 - parseInt(height) * (1 - scale) / 2
			const minLeft = parseInt(wrapWidth) - parseInt(width) - maxLeft
			const minTop = parseInt(wrapHeight) - parseInt(height) - maxTop
			let left = m41 - deltaX
			let top = m42 - deltaY
			left = left > maxLeft ? maxLeft : left < minLeft ? minLeft : left
			top = top > maxTop ? maxTop : top < minTop ? minTop : top
			if (parseInt(width) * scale < parseInt(wrapWidth) || parseInt(height) * scale < parseInt(wrapHeight) || scale < 0.01 || scale > 257) {
				return // 只对缩放有效
			}
			this.editor.style.transform = `translate(${left}px, ${top}px) scale(${this.editorScale = scale})`
		},
		// 【end】editor view controller
		async drawStart(e) {
			if (['f', 'g'].includes(this.curTool)) {
				const newStyle = { ...this.$app.newStyle }
				Object.keys(newStyle).forEach(key => ['', undefined, null].includes(newStyle[key]) && delete newStyle[key])
				await this.addWidget(e, {
					type: this.curTool === 'g' ? 'group' : 'frame',
					style: this.curTool === 'f' ? newStyle : {},
					classList: this.curTool === 'f' ? [...(!this.getApp('status.cursorOnWidget') ? ['uix-root-dev'] : []), 'uix-base'] : [],
					tagName: 'div'
				})
				// this.$nextTick(() => {
				// 	this.dragstartResizer('_right_bottom')
				// })
			}
		},
		drawOver(e) {
			// console.warn('drawOver')
			this.dropWidget(e)
		},
		dragstartResizer(handler, e) {
			// 防止拖拽的时候 显示跟随的影子
			if (e) {
				e.target.style.opacity = 0
				setTimeout(() => {
					e.target.style.opacity = 1
				})
			}
			// this.drawOver(e)
			// this.offsetStart = this.getStyle(this.dragHandler = handler, true)
			// this.offsetStyle = { ...getComputedStyle(this.curWidget.dom) }
			this.dragHandler = handler
			// 拖动左上的操纵杆时 固定右下的位置
			const { right, bottom } = this.getStyles(['right', 'bottom'])
			this.setStyle('right', right)
			this.setStyle('bottom', bottom)
		},
		dragstartWidget(e, widget) {
			this.setApp('dragWidgetDom', widget.dom)
			this.setApp('curWidget', widget)
			this.offsetXY = {
				x: e.offsetX,
				y: e.offsetY
				// x: e.layerX,
				// y: e.layerY
			}
		},
		dragoverThrottle: throttle(function (e) { this.dragover(e) }, 10),
		dragover(e) {
			this.maskShow = true // 防止控件移动的时候 鼠标事件的offset指向控件 而不是editor
			// console.log('dragover::', this.dragHandler, e)
			if (this.dragHandler) {
				let { width: parentWidth, height: parentHeight } = this.curParentStyle // this.curWidget.$parent().getStyles(['width', 'height'], true)
				// let { width: widgetWidth, height: widgetHeight } = this.offsetStyle
				parentWidth = parseInt(parentWidth)
				parentHeight = parseInt(parentHeight)
				// widgetWidth = parseInt(widgetWidth)
				// widgetHeight = parseInt(widgetHeight)
				const { x, y } = this.parentsOffset()
				// offsetX offsetY 为鼠标相对父级的偏移量
				const offsetX = e.offsetX - x
				const offsetY = e.offsetY - y
				const props = (this.dragHandler || '').replace(/^_/, '').split('_')
				const handle = x => x + 'px'
				const { right, bottom } = this.getStyles(['right', 'bottom'], true)
				this.setStyles(this.getStyle('position') !== 'absolute' ? {
					...(props.concat(['left', 'right']).hasRepeat() ? { width: offsetX - this.getProp('offsetLeft') + 'px' } : {}),
					...(props.concat(['top', 'bottom']).hasRepeat() ? { height: offsetY - this.getProp('offsetTop') + 'px' } : {})
				} : {
					...this.$pick({
					top: (parentHeight - offsetY) < bottom ? parentHeight - bottom : offsetY,
					right: parentWidth - offsetX,
					left: (parentWidth - offsetX) < right ? parentWidth - right : offsetX,
					bottom: parentHeight - offsetY
				}, props, handle),
					width: 'auto',
					height: 'auto'
				})
			}
			setTimeout(() => {
				this.$app.$forceUpdate()
			}, 10)
			// TODO group items
			// this.$nextTick(() => {
			// 	if (this.curTool === 'g') {

			// 	}
			// })
		},
		dragleave(e) {
			if (e.target === this.$refs.mask) {
				this.dropWidget(e)
			}
		},
		drop(e) {
			const { dataTransfer: data = {} } = e
			if (data.getData('widget-add')) {
				this.addWidget(e, JSON.parse(data.getData('widget-add')))
			} else {
				this.dropWidget(e)
			}
		},
		addWidget(e, widgetData) {
			const widget = new this.Widget(JSON.parse(JSON.stringify(widgetData)))
			return new Promise(resolve => {
				let indexes = []
				let index = 0
				const target = e?.target?.widget
				if (this.curTool !== 'g' && target instanceof this.Widget) {
					widget.parent = target
					indexes = target.indexes
					index = target.children.push(widget) - 1
				} else {
					index = this.widgets.push(widget) - 1
				}
				this.setApp('widgets', this.widgets)
				this.$nextTick(() => {
					widget.dom = this.getWidgetDom(widget.indexes = [...indexes, index])
					widget.dom.widget = widget
					// TODO 其实这时才真正初始完成 widget 构造器里应该有个触发器
					// console.warn('addWidget', widget)
					this.setApp('curWidget', widget)
					this.$nextTick(() => {
						this.dropWidget(e, widget.dom)
						this.$nextTick(resolve)
					})
				})
			})
		},
		dropWidget(e, dragWidgetDom = this.dragWidgetDom) {
			// console.warn('dropWidget::', dragWidgetDom, e)
			// 新加widget时 和 移动widget后 会进下面的判断
			if (e != undefined && dragWidgetDom != undefined) {
				// let { width: widgetWidth, height: widgetHeight } = this.offsetStyle
				// let { width: parentWidth, height: parentHeight } = this.curParentStyle // this.curWidget.$parent().getStyles(['width', 'height'], true)
				// parentWidth = parseInt(parentWidth)
				// parentHeight = parseInt(parentHeight)
				let { x, y } = this.parentsOffset() // 父级左上角的坐标
				if (e.type !== 'drop') {
					x = y = 0
				}
				// console.log('dropWidget e', e.offsetX, x, this.offsetXY.x)
				// cursor 为鼠标相对于父级的坐标
				const cursor = {
					x: e.offsetX - x - this.offsetXY.x,
					y: e.offsetY - y - this.offsetXY.y
				}
				if (this.getStyle('position') === 'static') {
					const w = cursor.x - this.getProp('offsetLeft')
					const h = cursor.y - this.getProp('offsetTop')
					const direction = this.curWidget.parent.getStyle('flex-direction')
					this.setStyle('width', (direction === 'row-reverse' ? -w : w) + 'px')
					this.setStyle('height', (direction === 'column-reverse' ? -h : h) + 'px')
				} else {
					this.setStyle('left', cursor.x + 'px')
					this.setStyle('top', cursor.y + 'px')
				// 	this.setStyle('right', parentWidth - (e.offsetX - x - this.offsetXY.x + this.getStyle('width', true)) + 'px')
				// 	this.setStyle('bottom', parentHeight - (e.offsetY - y - this.offsetXY.y + this.getStyle('height', true)) + 'px')
				}
			}
			// 新加widget时 和 移动widget时 有dragWidgetDom 改变大小时 没有dragWidgetDom
			// 一般改变大小才会进下面的判断 但不排除移动的时候也可能会触发
			// TODO 能不能省略该步骤 即元素都是 inset 上下左右来定位，宽高通过 getComputedStyle 来获取
			if (this.curWidget && dragWidgetDom == undefined) {
				const { width, height } = this.getStyles(['width', 'height'])
				this.setStyle('width', width)
				this.setStyle('height', height)
				this.removeStyle('right')
				this.removeStyle('bottom')
			}
			this.offsetXY = {
				x: 0,
				y: 0
			}
			this.maskShow = false
			this.dragHandler = null
			// this.offsetStyle = null
			// this.offsetStart = 0
			this.setApp('dragWidgetDom', null)
		}
	},
	mounted() {
		document.addEventListener('keydown', e => {
			if (e.key === 'Shift') {
				this.setApp('shiftKey', true)
			}
			if (this.getApp('status.cursorOnInput')) return
			if (this.$app.tools.map(o => o.key).includes(e.key)) {
				this.setApp('curTool', e.key)
			}
			if (e.shiftKey && e.key === ')') {
				this.centerEditor()
			}
			if (e.shiftKey && e.key === '!') {
				this.zoomToAll()
			}
			if (e.shiftKey && e.key === '@') {
				this.zoomToSelection()
			}
			if (e.shiftKey && e.key === '#') {
				this.centerSelection()
			}
			if (e.metaKey && ['0', '=', '-'].includes(e.key)) {
				// TODO 抽成方法
				e.preventDefault()
				const { m11 } = this.editorMatrix()
				this.transformEditor({scale: e.key === '=' ? m11 * 2 : e.key === '-' ? m11 / 2 : 1})
			}
			if (e.key === 'Backspace' || e.key === 'Delete') {
				if (!this.curWidget) return
				let widgets = this.widgets
				let length = 0
				this.curWidget.indexes.forEach((n, i) => {
					if (i < this.curWidget.indexes.length - 1) {
						widgets = widgets[n].children
						length = i + 1
					}
				})
				let index = this.curWidget.indexes[length]
				widgets.splice(index, 1)
				const due = item => {
					item.indexes[length] = item.indexes[length] - 1;
					(item.children || []).forEach(due)
				}
				widgets.forEach((item, i) => {
					if (i >= index) {
						due(item)
					}
				})
				this.setApp('curWidget', null)
			}
		})
		document.addEventListener('keyup', e => {
			if (e.key === 'Shift') {
				this.setApp('shiftKey', false)
			}
		})
		setTimeout(() => {
			this.centerEditor()
			this.$nextTick(() => {
				this.$app.$forceUpdate()
			})
		})
	}
}
</script>

<style scoped>
	.center {
		position:absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		user-select: none;
	}
	.editor-wrap {
		border: 1px solid #ccc;
		flex-grow: 1;
		position: relative;
		transform: scale(1);
		overflow: hidden;
	}
	.editor {
		background-color: #111111;
		background-color: #dddddd;
		/* width: 999999px;
		height: 999999px; */
		width: 2000px;
		height: 2000px;
		position: relative;
		transform: scale(1);
	}
	.mask-over {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 10000;
		background-color: rgba(0, 0, 0, 0);
	}

</style>

<style>
	[draggable] {
		cursor: pointer;
	}
</style>
