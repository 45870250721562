import Vue from 'vue'
import App from './App'
import '@/assets/css/global.scss'
import '@/assets/css/uix-base.css'

const isDev = !0

Vue.config.productionTip = !isDev

Array.prototype.hasRepeat = function () {
	return new Set(this).size < this.length
}

HTMLElement.prototype.toggle = function (prop) {
	if (prop) {
		this[prop] = !this[prop]
	} else {
		const display = getComputedStyle(this).display
		if (display !== 'none') {
			this._previous_display = display
			this.style.display = 'none'
		} else {
			this.style.display = this._previous_display || ''
		}
	}
	return this
}

// 可选链
export function getByPath (path, obj) {
	const paths = path.split('.')
	let node = obj || this
	for (let i = 0; i < paths.length; ++i) {
		if (node[paths[i]] instanceof Object || node[paths[i]] instanceof Array) {
			node = node[paths[i]]
		} else {
			return node[paths[i]]
		}
	}
	return node
}

export function setByPath (path, value, obj) {
	const paths = path.split('.')
	let node = obj || this
	for (let i = 0; i < paths.length - 1; ++i) {
		if (node[paths[i]] instanceof Object || node[paths[i]] instanceof Array) {
			node = node[paths[i]]
		} else {
			throw new Error(obj, 'has no this path', path)
		}
	}
	node[paths[paths.length - 1]] = value
	return node
}

Vue.prototype._get = getByPath
Vue.prototype._set = setByPath

Vue.prototype.$log = isDev ? console.log : () => {}

Vue.prototype.$toggle = (obj = {}, prop) => {
	const value = typeof obj === 'object' && prop ? obj[prop] : obj
	return [0, 1].includes(value) ? obj[prop] = 1 - value : obj[prop] = !value
}

Vue.prototype.$pick = (obj, props, handle = x => x) => {
	const res = {}
	Object.keys(obj).forEach(prop => {
		if (props.includes(prop)) {
			res[prop] = handle(obj[prop])
		}
	})
	return res
}

Vue.prototype.$omit = (obj, props, handle = x => x) => {
	const res = {}
	Object.keys(obj).forEach(prop => {
		if (!props.includes(prop)) {
			res[prop] = handle(obj[prop])
		}
	})
	return res
}

const numberProps = Vue.prototype.numberProps = 'margin,padding,marginLeft,marginRight,marginTop,marginBottom,left,top,right,bottom,width,height,minWidth,minHeight,maxWidth,maxHeight,lineHeight,padding,margin,borderWidth,fontSize,letterSpacing,wordSpacing,gap,rowGap,columnGap'.split(',')
const classArray = classes => typeof classes === 'string' ? classes.split(' ') : Array.from(classes)
const classString = classes => classes instanceof Array ? classes.join(' ') : classes.toString()
let WIDGET_COUNT = 0
class Widget {
	constructor(data = {}) {
		if (data.type === 'frame') {
			++WIDGET_COUNT
		}
		Object.assign(this, {
			name: data.type === 'group' ? '选组' : '格子 ' + WIDGET_COUNT,
			id: Number(Date.now() + ('.' + (data.type === 'frame' ? WIDGET_COUNT : 0))),
			indexes: [],
			children: []
		}, data)
	}
	$parent() {
		return this.parent || this.dom.parentElement
	}
	setProp(prop, value, obj) {
		if (!obj && this instanceof Widget && this.dom) {
			setByPath(prop, value, this.dom)
		}
		setByPath(prop, value, obj || this) // 同步 widget 的 style
	}
	setStyle(prop, value, obj) { // this 为 widget
		if ((prop === 'flex-wrap' && value === 'nowrap') ||
			[undefined, null, NaN, '', 'auto', 'normal', 'initial'].includes(value)) {
			this.removeStyle(prop, obj)
		} else {
			this.setProp('style.' + prop, value, obj)
		}
		window.$app.setWrapperStyle()
		window.$app.$forceUpdate()
	}
	setStyles(styles, obj) { // this 为 widget
		Object.keys(styles).forEach(prop => this.setStyle(prop, styles[prop], obj))
	}
	removeStyle(prop, obj = this.dom) {
		obj.style.removeProperty(prop)
		window.$app.$forceUpdate()
	}
	getProp(prop = '', obj = (this.dom || this)) {
		if (~prop.indexOf('style.')) {
			prop = prop.replace('style.', '')
			return this.getStyle(prop)
		} else {
			return getByPath(prop, obj)
		}
	}
	getStyle(prop = '', numify = false, result = {}) {
		if (!this.dom) return
		const value = getComputedStyle(this.dom)[prop]
		return result[prop] = numify && numberProps.includes(prop) ? parseFloat(value) : value
	}
	getStyles(props = '', numify = false) { // 不传prop 则返回全部样式
		const dom = this.dom || this
		const styles = getComputedStyle(dom)
		// TODO 把 $parent 和 styles 放到 widget 获取到 dom 和 parent 时 进行变量初始化。styles 不会变成死值 getComputedStyle 得到的是实时更新的值
		let result = {}
		// const handle = prop => {
		// 	const value = styles[prop]
		// 	return result[prop] = numify && numberProps.includes(prop) ? parseFloat(value) : value
		// }
		if (props && typeof props === 'string') {
			return this.getStyle(props, numify)
		}
		if (props && typeof props === 'object') {
			props.forEach(prop => this.getStyle(prop, numify, result))
			return result
		}
		result = styles
		if (numify) numberProps.forEach(prop => result[prop] = parseFloat(result[prop]))
		return result
	}
	addClass(classes) {
		this.dom.classList.add(...classArray(classes))
		window.$app.$forceUpdate()
	}
	setClass(classes) {
		this.dom.className = classString(classes)
		window.$app.$forceUpdate()
	}
	setFlexClass(classes) {
		classes = classArray(classes)
		let pres = [];
		['h-', 'v-', 'hv-'].forEach(pre => {
			classes.forEach(item => {
				if (item.startsWith(pre)) {
					pres.push(pre)
				}
			})
		})
		this.removeFlexClass(pres)
		this.addClass(classes)
	}
	removeFlexClass(pres = ['h-', 'v-', 'hv-']) {
		const list = this.dom.classList
		pres.forEach(pre => {
			list.forEach(item => {
				if (item.startsWith(pre)) {
					list.remove(item)
				}
			})
		})
		window.$app.$forceUpdate()
	}
	removeFlexStyle(obj = (this.dom || this)) {
		Array.from(obj.style).forEach(key => {
			if (key.startsWith('flex') || key === 'justify-content' || key === 'align-content' || key === 'align-items') {
				this.removeStyle(key, obj)
			}
		})
	}
	// TODO 使其适用于普通dom 取 left top 对吗 是不是应该取 offsetXY
	// 只有有父级widget的widget才有值
	parentsOffset() {
		let x = 0
		let y = 0
		let widget = this
		while (widget.parent) {
			widget = widget.parent
			const { left, top } = getComputedStyle(widget.dom)
			x += parseFloat(left)
			y += parseFloat(top)
		}
		return {
			x,
			y
		}
	}
	isRoot() {
		return !this.parent
	}
}

Vue.prototype.Widget = Widget;

['isRoot', 'parentsOffset', 'setFlexClass', 'removeFlexClass', 'removeFlexStyle', 'setClass', 'addClass', 'getStyles', 'getStyle', 'setStyles', 'setStyle', 'removeStyle', 'getProp', 'setProp'].forEach(item => {
	HTMLElement.prototype[item] = function (...args) {
		return Widget.prototype[item].call(this, ...args)
	}
	Vue.prototype[item] = function (...args) {
		return this.curWidget ? this.curWidget[item](...args) : Widget.prototype[item].call(this, ...args)
	}
})
Vue.prototype.inputStyle = function inputStyle(prop, e, obj) {
	this.setStyle(prop, e.target.value + (numberProps.includes(prop) ? 'px' : ''), obj)
}

new Vue({
	render: h => h(App)
}).$mount('#app')

/*
 TODO
 1 放大缩小界面的时候 移动元素 会变成原始大小的样子
 2 wrapperStyle 是不是可以改成 上下左右定位 不需要 width height 这样更好？
 3 <template></template> 里的 getStyle 经常重复使用 改成计算属性 或者尝试别的方法进行优化
 4 newStyle 改为class样式表 减少行内样式
 5 setStyles 优化：多个样式改成设置一次style 而不是多次 setProp
 6 改 0,0 坐标为中心
 7 鼠标快速地甩子元素迅速放开 有定位BUG
*/
