export default {
	computed: {
		curTool() {
			return this.$app.curTool
		},
		// TODO 是否需要修剪
		dragWidgetDom() {
			return this.$app.dragWidgetDom
		},
		wrapperStyle() {
			return this.$app.wrapperStyle
		},
		selectFrames() {
			return this.$app.selectFrames
		},
		curWidget() {
			return this.$app.curWidget
		},
		widgets() {
			return this.$app.widgets
		},
		$app() {
			return this.$root.$children[0] || {}
		}
	},
	methods: {
		forceUpdate() {
			this.$app.forceUpdate()
			this.$forceUpdate()
		},
		setWrapperStyle(...args) {
			this.$app.setWrapperStyle(...args)
		},
		getApp(key) {
			return this._get(key, this.$app)
		},
		setApp(key, value) {
			this._set(key, value, this.$app)
		}
	}
}