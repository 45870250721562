var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.item.tagName,{ref:"widgetDom",tag:"component",class:[
		..._vm.item.classList || [],
		_vm.$style._widget_class__widget_wrap,
		{
		[_vm.$style._widget_class__show_frame_border]: _vm.$app.status.showFrameBorder,
		}
	],style:(_vm.item.style),attrs:{"draggable":_vm.curTool === 'v'}},_vm._l((_vm.item.children),function(item,index){return _c('WidgetVue',{key:item.id,ref:"widgets",refInFor:true,attrs:{"item":item,"indexes":[..._vm.indexes, index],"getWidget":_vm.getWidget,"editorScale":_vm.editorScale},on:{"dragstartWidget":function($event){return _vm.$emit('dragstartWidget', ...arguments)}},nativeOn:{"click":function($event){$event.stopPropagation();_vm.curTool === 'v' && _vm.setApp('curWidget', _vm.getWidget([..._vm.indexes, index]))},"dragstart":function($event){if($event.target !== $event.currentTarget)return null;_vm.$emit('dragstartWidget', $event, _vm.getWidget([..._vm.indexes, index]))}}})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }