<template>
	<div ref="wrapper" class="wrapper" style="position: absolute;" :draggable="curTool === 'v'">
		<!-- <div :class="$style._wrapper_border + ' ' + $style._around"></div> -->
		<div v-for="border in '_top,_right,_bottom,_left'.split(',')" :key="border"
		:style="{
			left: style.left + ('_right' === border ? style.width : 0) - 1 + 'px',
			top: style.top + ('_bottom' === border ? style.height : 0) - 1 + 'px',
			width: (['_right', '_left'].includes(border) ? 2 : style.width) + 'px',
			height: (['_right', '_left'].includes(border) ? style.height : 2) + 'px',
			transform: `scale${['_right','_left'].includes(border)?'X':'Y'}(${1/editorScale})`
		}"
		@mousedown.stop draggable="true" @dragstart="e => $emit('dragstartResizer', border, e)" :class="$style[border] + ' ' + $style._wrapper_border"></div>
		<div v-for="corner in '_bottom_left,_left_top,_top_right,_right_bottom'.split(',')" :key="corner"
		:style="{
			left: style.left + (['_top_right', '_right_bottom'].includes(corner) ? style.width : 0) - 3 + 'px',
			top: style.top + (['_bottom_left', '_right_bottom'].includes(corner) ? style.height : 0) - 3 + 'px',
			transform: `scale(${1/editorScale})`
		}"
		@mousedown.stop draggable="true" @dragstart="e => $emit('dragstartResizer', corner, e)" :class="$style[corner] + ' ' + $style._wrapper_corner"></div>
	</div>
</template>

<script>
import baseMixin from '../mixins/base'
export default {
	name: 'Wrapper',
	mixins: [baseMixin],
	computed: {
		style() {
			return {
				left: parseFloat(this.wrapperStyle.left),
				top: parseFloat(this.wrapperStyle.top),
				width: parseFloat(this.wrapperStyle.width),
				height: parseFloat(this.wrapperStyle.height)
			}
		}
	},
	methods: {
	},
	props: {
		editorScale: Number
	}
}
</script>

<style module lang="scss">
	._wrapper_border {
		display: block;
		position: absolute;
		z-index: 1;
		outline: 1px solid $c-rose;
		outline-offset: -1px;
	}
	._wrapper_border._around {
		background: transparent;
		border: 2px solid blue;
		z-index: -1;
		top: -1px;
		left: -1px;
		right: -1px;
		bottom: -1px;
		cursor: move;
	}
	._wrapper_border._top,._wrapper_border._bottom {
		width: 100%;
		height: 2px;
		cursor: row-resize;
	}
	._wrapper_border._top {
		top: -1px;
	}
	._wrapper_border._bottom {
		bottom: -1px;
	}
	._wrapper_border._right,._wrapper_border._left {
		width: 2px;
		height: 100%;
		cursor: col-resize;
	}
	._wrapper_border._right {
		right: -1px;
	}
	._wrapper_border._left {
		left: -1px;
	}

	._wrapper_corner {
		position: absolute;
		z-index: 2;
		background: #000;
		width: 6px;
		height: 6px;
	}
	._wrapper_corner._top_right {
		right: -3px;
		top: -3px;
		cursor: nesw-resize;
	}
	._wrapper_corner._right_bottom {
		right: -3px;
		bottom: -3px;
		cursor: nwse-resize;
	}
	._wrapper_corner._bottom_left {
		bottom: -3px;
		left: -3px;
		cursor: nesw-resize;
	}
	._wrapper_corner._left_top {
		left: -3px;
		top: -3px;
		cursor: nwse-resize;
	}
</style>
