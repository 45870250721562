<template>
<div class="flex-center ph-5 pv-5">
	<img v-for="item in $app.tools" :key="item.key" :style="item.style"
		class="icon" :class="{active: curTool === item.key}"
		:src="item.img" :title="item.title" :alt="item.title"
		@click="setApp('curTool', item.key)"/>
	<!-- <img src="@/assets/icon/icon-tool-img.svg" class="icon" title="图片" @dragstart="dragstart({
		src: '',
		alt: '点击上传',
		style: {
			textAlign: 'left',
			width: '50px',
			height: '50px',
			backgroundColor: '#ccc'
		},
		tagName: 'img'
	}, $event)"/>
	<img src="@/assets/icon/icon-tool-input.svg" class="icon" title="输入框" @dragstart="dragstart({
		placeHolder: '请输入',
		tagName: 'input'
	}, $event)"/> -->
</div>
</template>

<script>
import baseMixin from '../mixins/base'
export default {
	name: 'ToolBar',
	mixins: [baseMixin],
	methods: {
		dragstart(widget, e) {
			e.dataTransfer.setData('widget-add', JSON.stringify(widget))
		}
	},
	mounted() {
		this.setApp('curTool', 'f')
	}
}
</script>

<style scoped lang="scss">
	.icon {
		cursor: pointer;
		height: 30px;
		margin: 0 4px;
	}
	.icon.active {
		background: #fff;
		outline: 2px outset $c-sky;
	}
	/* .icon:active {
		cursor: grabbing;
	} */
</style>
