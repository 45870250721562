var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"wrapper",staticClass:"wrapper",staticStyle:{"position":"absolute"},attrs:{"draggable":_vm.curTool === 'v'}},[_vm._l(('_top,_right,_bottom,_left'.split(',')),function(border){return _c('div',{key:border,class:_vm.$style[border] + ' ' + _vm.$style._wrapper_border,style:({
		left: _vm.style.left + ('_right' === border ? _vm.style.width : 0) - 1 + 'px',
		top: _vm.style.top + ('_bottom' === border ? _vm.style.height : 0) - 1 + 'px',
		width: (['_right', '_left'].includes(border) ? 2 : _vm.style.width) + 'px',
		height: (['_right', '_left'].includes(border) ? _vm.style.height : 2) + 'px',
		transform: `scale${['_right','_left'].includes(border)?'X':'Y'}(${1/_vm.editorScale})`
	}),attrs:{"draggable":"true"},on:{"mousedown":function($event){$event.stopPropagation();},"dragstart":e => _vm.$emit('dragstartResizer', border, e)}})}),_vm._l(('_bottom_left,_left_top,_top_right,_right_bottom'.split(',')),function(corner){return _c('div',{key:corner,class:_vm.$style[corner] + ' ' + _vm.$style._wrapper_corner,style:({
		left: _vm.style.left + (['_top_right', '_right_bottom'].includes(corner) ? _vm.style.width : 0) - 3 + 'px',
		top: _vm.style.top + (['_bottom_left', '_right_bottom'].includes(corner) ? _vm.style.height : 0) - 3 + 'px',
		transform: `scale(${1/_vm.editorScale})`
	}),attrs:{"draggable":"true"},on:{"mousedown":function($event){$event.stopPropagation();},"dragstart":e => _vm.$emit('dragstartResizer', corner, e)}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }