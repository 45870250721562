<template>
	<select
		ref="formX"
		:value="prop.includes('newStyle.') ? getApp('newStyleCompose.' + style) : prop.includes('style.') ? getStyle(style, true) : getProp(prop, $app)"
		@change="change">
		<slot/>
	</select>
</template>
<script>
import baseMixin from '../mixins/base'
import formX from '../mixins/formX'
export default {
	name: 'SelectX',
	mixins: [baseMixin, formX]
}
</script>
<style lang="scss" scoped>
</style>
>